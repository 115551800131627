<template>
	<svg
		id="e5jcJekiFN21"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 108 28"
		shape-rendering="geometricPrecision"
		text-rendering="geometricPrecision"
		project-id="19baf13ea1f54b40957c846268f623e3"
		export-id="298f38a42bb84fc495e580b7e5aa8659"
		cached="false"
	>
		<path
			d="M15.9343,5.92353c.5557,0,1.0927.07484,1.6108.21518s1.008.34617,1.4601.60813c.4522.26196.8667.58006,1.2435.94493s.6971.77653.9703,1.22561.4804.93558.6311,1.45952.2261,1.0666.2261,1.6279c0,.4771-.0565.9449-.1601,1.394s-.2638.8795-.471,1.2818-.4522.7765-.7348,1.132c-.2826.3462-.6029.6643-.9515.9356l1.8935,3.7423c.0377.0655.066.131.1036.2058s.0566.1404.0659.1965c0,.1029-.0376.1871-.113.2433s-.1695.0842-.292.0842h-2.2138c-.2543,0-.4333-.131-.5181-.393l-1.4413-2.919c-.2072.0562-.4239.1029-.6406.131-.2166.0374-.4427.0561-.6687.0561h-3.1276v2.6852c0,.2993-.1601.4397-.4804.4397h-1.9217c-.3015,0-.45221-.1497-.45221-.4397v-14.37057c0-.32745.15071-.4865.45221-.4865h5.5297Zm3.3724,6.06257c0-.4398-.0754-.8608-.2355-1.2724-.1602-.4023-.3768-.76717-.65-1.09463s-.6029-.58942-.9891-.8046-.8008-.34616-1.2435-.4023c-.1884-.02807-.3768-.04678-.5652-.06549-.1884-.00936-.3863-.01871-.5841-.01871h-2.2232v7.28823h2.9862c.4993,0,.9609-.0936,1.3943-.2807s.8007-.4397,1.1115-.7672.5558-.711.7348-1.1508c.179-.4303.2638-.9168.2638-1.4314Z"
			fill="#1a1f36"
		/>
		<path
			d="M44.2136,20.6598c0,.1778-.0565.3182-.1602.421-.1036.103-.2543.1497-.4333.1497h-1.658c-.3768,0-.5746-.1871-.5746-.5707v-14.16465c0-.16841.0471-.30874.1507-.42101s.2449-.16841.4239-.16841h1.658c.1318,0,.2638.05614.3956.15905s.1979.25261.1979.43037v14.16465Z"
			fill="#1a1f36"
		/>
		<path
			d="M49.5269,20.6598c0,.1778-.0565.3182-.1601.421-.1037.103-.2544.1497-.4333.1497h-1.658c-.3768,0-.5746-.1871-.5746-.5707v-14.16465c0-.16841.0471-.30874.1507-.42101s.2449-.16841.4239-.16841h1.658c.1318,0,.2637.05614.3956.15905s.1978.25261.1978.43037v14.16465Z"
			fill="#1a1f36"
		/>
		<path
			d="M56.9965,19.0126c.3011,0,.6122-.0561.9418-.1591s.6407-.2713.9422-.4865c.0942-.0561.1788-.1029.2542-.1403.0753-.0374.1507-.0655.2449-.0655.0754,0,.1508.0187.2165.0561.0662.0375.1319.0936.1981.1871l.7722,1.0385c.0942.1123.1319.2339.1319.3649c0,.1123-.0377.2152-.1034.3088-.0662.0935-.1604.1777-.2639.2619-.2164.1684-.4522.3088-.716.4398-.2639.1309-.5369.2432-.8287.3367-.2924.0936-.5842.1591-.8857.2152-.3015.0468-.5934.0749-.8853.0749-.7068,0-1.3661-.1403-1.9879-.4117-.6123-.2713-1.1586-.6455-1.6202-1.1133s-.829-1.0198-1.0928-1.656c-.2637-.6362-.3956-1.3191-.3956-2.0583c0-.711.1224-1.3846.3768-2.0114.2544-.6362.6029-1.1883,1.0456-1.6748.4428-.4771.9703-.8513,1.5826-1.132.6029-.2807,1.2624-.421,1.9688-.421.6688,0,1.2906.1216,1.8748.3742.5837.2526,1.1022.5894,1.5448,1.0292.4426.4303.7915.9449,1.0456,1.5343.2542.5894.3861,1.2256.3861,1.9086v.0655c0,.262-.028.4771-.0942.6455-.0657.1684-.16.2994-.2822.393-.1319.0936-.2923.159-.4808.1964-.1884.0375-.4146.0562-.6687.0562h-5.5202c.0659.29.1789.552.3391.7765.1601.2339.3391.421.5464.5801.2072.159.4333.2806.6783.3649.2353.0748.4803.1216.7349.1216Zm2.0816-3.9108c-.0469-.2432-.1411-.4677-.273-.6829s-.2923-.393-.4899-.552-.4146-.2807-.65-.3649c-.2449-.0936-.4996-.131-.7726-.131-.2449,0-.4803.0374-.7161.1217-.2353.0748-.4425.1964-.6403.3367-.1979.1498-.358.3275-.4993.5427s-.2355.4584-.292.7297h4.3332Z"
			fill="#1a1f36"
		/>
		<path
			d="M63.5437,11.7429c0-.2058.0753-.3555.2164-.449.1416-.0936.2827-.1404.4335-.1404h1.1306c.1411,0,.2445.0374.3296.1216.0845.0749.1599.1778.2164.3088.0662.131.1228.2713.1793.4397s.1319.3462.2165.5239c.113-.2058.2261-.4023.358-.5894s.273-.3462.4426-.4958c.1696-.1497.3489-.262.5654-.3369.2164-.0748.4522-.1216.7252-.1216.2919,0,.5465.0187.7538.0562.2165.0373.4146.0935.603.1684.188.0748.3765.1777.5649.29.1885.1216.3957.2619.6311.4397.2261.1216.33.2713.33.4771c0,.1217-.0377.262-.1227.4023l-.7253,1.1321c-.0942.1684-.2353.29-.4049.3462s-.3392.0187-.49-.1217c-.1223-.1122-.245-.1964-.3484-.2713-.1039-.0655-.2169-.1216-.3203-.159-.1039-.0375-.2165-.0655-.3392-.0749-.1223-.0093-.245-.0187-.3958-.0187-.2073,0-.3861.0281-.546.0842-.1604.0562-.3015.1404-.4334.2433s-.2547.2339-.3677.3835c-.1131.1498-.2262.3088-.3296.4866v5.7444c0,.1965-.0468.3462-.1507.4584-.1034.103-.2638.1591-.49.1591h-1.6201c-.1977,0-.3581-.0561-.4615-.1591-.1039-.1028-.1604-.2525-.1604-.4584v-8.8693h.0097Z"
			fill="#1a1f36"
		/>
		<path
			d="M78.2024,5.45651c.0377-.06549.0846-.12163.1411-.1684.0662-.05614.1508-.07485.2735-.07485.1034,0,.1884.02806.2638.0842.0657.05613.1223.12162.1696.19647.0469.07485.0846.1497.1131.21519.028.06549.0565.12162.0754.14033l.1788.41166c1.0548,2.42315,2.0912,4.82759,3.118,7.22269s2.0632,4.7995,3.1089,7.2227c.0092.0187.0188.0561.028.0935.0097.0468.0189.0842.0189.1123c0,.2058-.1411.3087-.4146.3087h-2.0536c-.2827,0-.4711-.1309-.5745-.3929l-.5934-1.5156h-6.8865l-2.2324,5.3702c-.1038.2713-.3015.4116-.5841.4116h-2.044c-.1507,0-.2638-.028-.3203-.0841-.0662-.0562-.0942-.131-.0942-.2246c0-.0374.0092-.0842.0188-.1216.0092-.0375.0189-.0748.0377-.1123c1.036-2.4231,3.7303-8.6915,4.7571-11.0866c1.0268-2.3858,2.0724-4.79019,3.1277-7.20399.0565-.14034.113-.27132.1788-.41166.0473-.12162.113-.26196.1884-.39294Zm.4238,4.96789c-.0469.131-.0942.262-.1411.393-.0474.131-.0942.2619-.1416.4023L76.045,16.9642h5.1344l-2.2706-5.7445c-.0376-.131-.0845-.262-.1319-.393-.0565-.1403-.1034-.2713-.1507-.4023Z"
			fill="#1a1f36"
		/>
		<path
			d="M97.118,20.6224c0,.1778-.0473.3181-.1507.4304-.1039.1029-.245.1684-.4334.1777h-1.0176c-.1131,0-.2165,0-.3107-.0093-.0943-.0094-.1793-.0281-.2639-.0655-.0753-.0374-.1507-.0842-.2073-.1591-.0565-.0654-.1034-.1684-.141-.2993l-.1131-.5894c-.3489.4303-.735.7578-1.1587.9823-.4243.2246-.9514.3275-1.5641.3275-.6876,0-1.3279-.1404-1.912-.4117-.5934-.2713-1.1022-.6456-1.5448-1.1227-.4334-.4771-.7726-1.0291-1.0268-1.656-.245-.6362-.3677-1.3098-.3677-2.0302c0-.7297.1227-1.4127.3677-2.0489.2445-.6362.5837-1.1882,1.0171-1.656.4335-.4678.9514-.842,1.5545-1.1133.603-.2713,1.2529-.4117,1.9593-.4117.5277,0,.9983.1029,1.4037.3181.4146.2058.7533.4584,1.0268.7391v-5.51054c0-.17776.0565-.32745.1696-.43972s.273-.16841.4803-.16841h1.5636c.1793,0,.3392.05614.4711.16841.132.11227.2073.26196.2073.43972v14.10854h-.0092Zm-2.6097-4.3598c0-.3649-.0657-.7017-.1884-1.0198-.1223-.318-.2919-.5894-.5084-.8326-.2169-.2339-.4619-.421-.7537-.5614-.2919-.1403-.5934-.2058-.9046-.2058-.3203,0-.6214.0655-.8949.2058-.2826.1404-.5276.3275-.7344.5614-.2073.2339-.3769.5052-.4996.8233-.1223.3087-.1788.6455-.1788,1.0011c0,.3461.0565.6829.1788.9917.1227.3087.2826.58.4996.8139.2068.2339.4518.4117.7344.5427s.5842.2058.8949.2058c.3204,0,.6219-.0655.9046-.2058.2826-.131.5368-.3181.7441-.5427.2165-.2245.3861-.4959.518-.7952.1227-.3088.1884-.6362.1884-.9824Z"
			fill="#1a1f36"
		/>
		<path
			d="M99.2848,19.8277c-.0846-.0936-.1503-.1871-.1976-.2713-.0469-.0842-.0754-.1965-.0754-.3275c0-.0561.0096-.1216.0285-.1964.0188-.0749.0942-.1685.2445-.2714l1.0932-.8981h-.01c-.028,0-.028-.0094-.009-.0187s.047-.0188.084-.0281c.038-.0094.076-.0187.123-.0281.038-.0094.076-.0187.094-.0187.095,0,.179.0281.264.0936.076.0655.141.1403.208.2245.113.1684.245.2994.414.4117.169.1029.339.1964.518.2619s.358.1123.527.1404c.17.028.321.0374.443.0374.113,0,.245-.0187.386-.0468.142-.0281.273-.0748.396-.1403.122-.0655.226-.1403.311-.2246.085-.0935.123-.1964.123-.3181c0-.131-.048-.2432-.132-.3461-.095-.103-.208-.1965-.368-.2807-.151-.0842-.33-.1591-.527-.2339-.198-.0748-.415-.1403-.641-.1965-1.093-.3087-1.922-.7391-2.496-1.2817-.5654-.5426-.8481-1.2069-.8481-2.0022c0-.4397.0942-.842.2827-1.1975.1884-.3649.4523-.6736.7914-.9356.339-.2619.735-.4678,1.196-.6081.462-.1403.971-.2152,1.526-.2152c1.423,0,2.563.552,3.439,1.6466.037.0561.075.1123.084.1684.019.0562.029.1123.029.1497-.01.0936-.038.1591-.085.2152-.038.0561-.113.1217-.207.1965h.009l-.028.028-.019.0188-.094.1122-.283.2339c-.179.1497-.33.2807-.452.393-.123.1122-.245.1684-.368.159-.113,0-.207-.0281-.273-.0748-.066-.0562-.131-.1216-.207-.1965-.094-.1029-.197-.1965-.32-.2901-.122-.0935-.254-.1683-.386-.2432s-.274-.131-.415-.1684-.273-.0655-.396-.0655c-.263,0-.471.0281-.621.0936-.151.0655-.264.1403-.349.2245-.075.0842-.132.1778-.16.2713-.028.0936-.037.1684-.037.2246c0,.1403.037.2526.122.3461s.188.1778.32.2433.273.1216.434.1684c.16.0468.31.0842.452.131l.179.0561c.527.1404,1.008.3088,1.45.4959.443.1871.82.4116,1.14.6642.32.262.565.5614.745.9075.178.3462.263.7578.263,1.2256c0,.5053-.103.9544-.32,1.3473s-.509.7298-.886,1.001c-.376.2714-.81.4772-1.318.6269-.509.1403-1.046.2152-1.63.2152-.33,0-.669-.0281-.999-.0936-.339-.0655-.659-.1684-.97-.2994s-.603-.2993-.876-.4958c-.2543-.2152-.4993-.4491-.7162-.7204Z"
			fill="#1a1f36"
		/>
		<path
			d="M62.984,22.4896c1.238.9269,1.238,2.7772,0,3.6728v0c-.1403.1015-.294.1873-.4541.254v0c-1.4093.5873-3.0277-.3457-3.2052-1.8715v0c-.0205-.1765-.0205-.3544,0-.5304v0c.1775-1.5212,1.7959-2.4133,3.2052-1.7904v0c.1601.0708.3138.1605.4541.2655v0Z"
			fill="#7d98ff"
		/>
		<g>
			<path d="M59.1133,2.97898l1.9758-1.80723.4012,2.70045c-.8396-.15038-1.6458-.45301-2.377-.89322Z" fill="#3d65ff" />
			<path
				d="M58.362,3.66624l-1.9591,1.7919c1.7232.90944,3.6169,1.4605,5.5631,1.61702l-.3248-2.18707c-1.1689-.1606-2.29-.57772-3.2792-1.22185Z"
				fill="#3d65ff"
			/>
			<path
				d="M62.1137,8.06924c-2.2751-.14988-4.4887-.80338-6.4769-1.9103L53.9319,7.71835c2.6387,1.41115,5.5384,2.29415,8.5153,2.59655l-.3335-2.24566Z"
				fill="#3d65ff"
			/>
		</g>
		<path
			d="M87.8332,2.56789c1.7788.26176,2.8037,2.15308,2.0344,3.75427v0c-.0872.18146-.1968.35429-.3234.51114v0c-1.1153,1.38098-3.2865,1.32374-4.313-.13762v0c-.1187-.16899-.2173-.3509-.2938-.54209v0c-.6613-1.65331.4989-3.4617,2.2846-3.60554v0c.2028-.01634.4096-.00982.6112.01984v0Z"
			fill="#bdcaff"
		/>
		<path
			d="M22.6724,4.38163c.8283-1.10614,2.4951-1.10614,3.3331,0v0c.0952.12561.1758.26212.2396.4061v0c.5571,1.25525-.259,2.68565-1.6206,2.84067l-.0381.00433c-.1549.01764-.3114.01764-.4665,0l-.0381-.00433c-1.363-.15502-2.1916-1.58542-1.6455-2.84067v0c.0626-.14398.142-.28049.2361-.4061v0Z"
			fill="#7d98ff"
		/>
		<path
			d="M4.06334,13.8806c1.95842.2881,3.08678,2.3704,2.23979,4.1332v0c-.09598.1997-.21665.39-.3561.5627v0c-1.22782,1.5204-3.6182,1.4574-4.74832-.1515v0c-.13068-.1861-.239214-.3863-.323409-.5968v0c-.72809-1.8202.549269-3.8111,2.515159-3.9695v0c.22328-.018.45094-.0108.67288.0219v0Z"
			fill="#bdcaff"
		/>
		<g id="e5jcJekiFN218" transform="translate(.000001 0)">
			<path
				d="M31.289,7.61688c-4.3953,0-7.9511,3.56522-7.9511,7.95112s3.5652,7.9511,7.9511,7.9511c4.3858,0,7.9511-3.5653,7.9511-7.9511s-3.5559-7.95112-7.9511-7.95112ZM35.7974,20.0387c-.5659.5659-1.245,1.0092-2.009,1.3393-.7734.3301-1.594.4904-2.4617.4904-.8866,0-1.7166-.1603-2.49-.4904s-1.4431-.7734-2.0184-1.3393c-.566-.5659-1.0187-1.2356-1.3488-1.9996s-.4999-1.5845-.4999-2.4617.1697-1.6977.4999-2.4617.7828-1.4242,1.3488-1.9902s1.245-.9997,2.0184-1.32988c.7734-.32066,1.6034-.49043,2.49-.49043.8677,0,1.6883.16034,2.4617.49043.7734.32068,1.4431.76398,2.009,1.32988s1.0187,1.2262,1.3488,1.9902.4999,1.5845.4999,2.4617-.1698,1.6977-.4999,2.4617-.7734,1.4337-1.3488,1.9996Z"
				fill="#3d65fd"
			/>
			<path
				d="M31.2892,10.5313c-2.7824,0-5.0366,2.2542-5.0366,5.0366s2.2542,5.0366,5.0366,5.0366s5.0367-2.2542,5.0367-5.0366-2.2543-5.0366-5.0367-5.0366Zm0,8.583c-1.9524,0-3.537-1.5845-3.537-3.537c0-1.9524,1.5846-3.5369,3.537-3.5369s3.537,1.5845,3.537,3.5369c0,1.9525-1.5846,3.537-3.537,3.537Z"
				fill="#3d65fd"
			/>
			<path
				d="M31.3364,17.5589c1.1147,0,2.0184-.9037,2.0184-2.0184s-.9037-2.0184-2.0184-2.0184c-1.1148,0-2.0185.9036-2.0185,2.0184s.9037,2.0184,2.0185,2.0184Z"
				fill="#3d65fd"
			/>
		</g>
		<path
			id="e5jcJekiFN222"
			d="M31.2919,23.5188c4.3929,0,7.954-3.5612,7.954-7.9541s-3.5611-7.95398-7.954-7.95398-7.954,3.56118-7.954,7.95398s3.5611,7.9541,7.954,7.9541Zm-2.2114-3.0367c.6183.3291,1.3114.4937,2.0793.4937.7579,0,1.4261-.1496,2.0045-.4488.5884-.3092,1.0521-.733,1.3912-1.2715.349-.5485.5235-1.1768.5235-1.8849c0-.6681-.1545-1.2565-.4637-1.7651-.2992-.5086-.713-.9075-1.2416-1.1967-.5285-.2992-1.1269-.4488-1.7951-.4488-.339,0-.6233.0449-.8526.1346-.1106.0385-.2084.0827-.2935.1328-.0743.0437-.1941-.0234-.1791-.1082l.3553-2.0176c.0072-.0412.0431-.0713.085-.0713h3.6259c.0476,0,.0863-.0387.0863-.0864v-1.6523c0-.0477-.0387-.0863-.0863-.0863h-5.0782c-.0413,0-.0769.0293-.0848.0698l-1.193,6.1401c-.0104.0533.0304.1028.0847.1028h1.5978c.0297,0,.0572-.0154.0736-.04.1668-.2496.3875-.4407.6622-.5733.2892-.1496.5934-.2244.9125-.2244.3291,0,.6233.0748.8826.2244.2593.1396.4637.344.6133.6133.1496.2593.2244.5584.2244.8975c0,.3491-.0798.6582-.2394.9275-.1495.2692-.364.4787-.6432.6283s-.5984.2243-.9574.2243c-.4487,0-.8826-.1146-1.3014-.344-.3801-.2132-.7085-.4954-.9852-.8466-.033-.0418-.0956-.0459-.1326-.0075L27.7194,19.074c-.0267.0277-.0319.0699-.012.1028.3271.5424.7848.9775,1.3731,1.3053Z"
			transform="matrix(0 0 0 1 31.2919 0)"
			clip-rule="evenodd"
			fill="#3d65ff"
			fill-rule="evenodd"
		/>
		<ellipse
			id="e5jcJekiFN223"
			rx="1.009225"
			ry="8.238587"
			transform="matrix(1 0 0 0.965106 31.289001 15.56799)"
			opacity="0"
			fill="#3d65fd"
			stroke-width="0"
		/>
	</svg>
</template>

<script>
	export default {
		name: 'RaLogo',
		mounted() {
			const plugin = document.createElement('script');
			plugin.setAttribute('src', '/scripts/ra-logo.js');
			plugin.async = true;
			document.head.appendChild(plugin);
		},
	};
</script>
