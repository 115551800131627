<template>
	<svg
		id="eTGSByvcL0H1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 27 21"
		shape-rendering="geometricPrecision"
		text-rendering="geometricPrecision"
		project-id="ee52adec7cd24130b8e13e36ba03cdfa"
		export-id="835480607a104685be944754b22a58c1"
		cached="false"
	>
		<g id="eTGSByvcL0H2">
			<path
				d="M12.5,1C7.24848,1,3,5.25977,3,10.5s4.25975,9.5,9.5,9.5s9.5-4.2598,9.5-9.5-4.2485-9.5-9.5-9.5Zm5.3867,14.8416c-.6762.6762-1.4876,1.2058-2.4003,1.6002-.9242.3945-1.9046.586-2.9413.586-1.0594,0-2.051-.1915-2.97515-.586s-1.72418-.924-2.41159-1.6002c-.67619-.6761-1.21712-1.4763-1.61152-2.389-.39445-.9129-.59729-1.8933-.59729-2.9413s.20284-2.0285.59729-2.94134.93533-1.70163,1.61152-2.37782s1.48753-1.19452,2.41159-1.58897c.92415-.38312,1.91575-.58597,2.97515-.58597c1.0367,0,2.0171.19157,2.9413.58597.924.38318,1.7241.91284,2.4003,1.58897s1.2171,1.46504,1.6115,2.37782.5973,1.89325.5973,2.94134-.2029,2.0284-.5973,2.9413c-.3944.9127-.9241,1.7129-1.6115,2.389Z"
				fill="#3d65fd"
			/>
			<path
				d="M12.5003,4.48216c-3.32444,0-6.01781,2.69332-6.01781,6.01784c0,3.3244,2.69337,6.0177,6.01781,6.0177s6.0178-2.6933,6.0178-6.0177c0-3.32452-2.6934-6.01784-6.0178-6.01784Zm0,10.25504c-2.3328,0-4.226-1.8932-4.226-4.226s1.8932-4.22596,4.226-4.22596s4.226,1.89319,4.226,4.22596-1.8933,4.226-4.226,4.226Z"
				fill="#3d65fd"
			/>
			<path
				d="M12.5566,12.8787c1.3319,0,2.4117-1.0797,2.4117-2.4115c0-1.33196-1.0798-2.41167-2.4117-2.41167s-2.4116,1.07971-2.4116,2.41167c0,1.3318,1.0797,2.4115,2.4116,2.4115Z"
				fill="#3d65fd"
			/>
		</g>
		<circle r="1.5" transform="translate(22.5 1.5)" fill="#7d98ff" />
		<circle r="1.5" transform="translate(2.5 18.5)" fill="#7d98ff" />
		<circle r="2.5" transform="translate(24.5 16.5)" fill="#bdcaff" />
		<circle r="2.5" transform="translate(2.5 2.5)" fill="#bdcaff" />
		<circle id="eTGSByvcL0H10" r="1.5" transform="matrix(1 0 0 6.333333 12.495623 10.5)" opacity="0" fill="#3d65fd" />
		<path
			id="eTGSByvcL0H11"
			d="M12.5,20c5.2467,0,9.5-4.2533,9.5-9.5s-4.2533-9.5-9.5-9.5-9.5,4.25329-9.5,9.5s4.25329,9.5,9.5,9.5ZM9.85841,16.3728c.73849.3931,1.56629.5896,2.48349.5896.9052,0,1.7032-.1786,2.3941-.536.7027-.3692,1.2566-.8754,1.6616-1.5186.4168-.6551.6253-1.4055.6253-2.2512c0-.798-.1846-1.5008-.5539-2.1082-.3573-.6075-.8516-1.08395-1.4829-1.42937-.6313-.35733-1.3459-.53599-2.144-.53599-.405,0-.7444.0536-1.0184.1608-.147.05114-.2752.11087-.3845.17918-.0774.04836-.2026-.02083-.1868-.11071l.433-2.45848c.0076-.04371.0456-.07557.09-.07557h4.3521c.0505,0,.0914-.04094.0914-.09143v-1.99686c0-.0505-.0409-.09143-.0914-.09143h-6.0865c-.04379,0-.08142.03102-.08977.07399L8.52171,11.5293c-.01097.0565.03226.1089.08975.1089h1.92904c.0314,0,.0605-.0162.0778-.0424.1996-.3007.4644-.5308.7945-.6901.3454-.1787.7087-.268,1.0899-.268.393,0,.7444.0893,1.0541.268.3097.1667.5538.4109.7325.7325.1787.3097.268.667.268,1.072c0,.4169-.0953.7861-.2859,1.1077-.1786.3216-.4347.5718-.7682.7504s-.7147.268-1.1435.268c-.536,0-1.0541-.137-1.5544-.4109-.4579-.2569-.85292-.5975-1.18516-1.0219-.03482-.0445-.10113-.0488-.14031-.0081l-1.25312,1.302c-.02827.0294-.03376.074-.01277.1089.39103.6513.93919,1.1734,1.64447,1.5665Z"
			transform="matrix(0 0 0 1 12.5 0)"
			clip-rule="evenodd"
			fill="#3d65ff"
			fill-rule="evenodd"
		/>
	</svg>
</template>

<script>
	export default {
		name: 'RaLogoSmall',
		mounted() {
			const plugin = document.createElement('script');
			plugin.setAttribute('src', '/scripts/ra-logo-small.js');
			plugin.async = true;
			document.head.appendChild(plugin);
		},
	};
</script>
