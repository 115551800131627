<template>
	<div :class="{ 'anniversary-logo': isMenuVisible, 'anniversary-logo-small': !isMenuVisible }">
		<template v-if="isDark">
			<ra-logo-dark v-if="isMenuVisible" />
			<ra-logo-small-dark v-else />
		</template>
		<template v-else>
			<ra-logo v-if="isMenuVisible" />
			<ra-logo-small v-else />
		</template>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import RaLogo from '@/components/logo/RaLogo.vue';
	import RaLogoDark from '@/components/logo/RaLogoDark.vue';
	import RaLogoSmall from '@/components/logo/RaLogoSmall.vue';
	import RaLogoSmallDark from '@/components/logo/RaLogoSmallDark.vue';
	import EventBus from '@/services/local/EventBusService';
	import User from '@/services/local/UserService';

	export default defineComponent({
		components: { RaLogoSmall, RaLogo, RaLogoSmallDark, RaLogoDark },
		props: {
			isDark: {
				type: Boolean,
			},
		},
		data() {
			return {
				user: User.currentUser.user || {},
				isMenuVisible: true,
			};
		},

		mounted() {
			EventBus.on('menuToggled', this.menuToggledHandler.bind(this));

			localStorage.isMenuVisible === 'false' ? (this.isMenuVisible = false) : (this.isMenuVisible = true);
		},

		methods: {
			menuToggledHandler(val) {
				this.isMenuVisible = this.isMenuVisible !== val;
			},
		},
	});
</script>
